<template>
  <div>
    <!-- ======= Contact Section ======= -->
    <section id="contact" class="contact section-bg ">
      <div class="container">

        <div class="section-title " data-aos="fade-up">
          <h2>{{ $t("contactUs.header") }}</h2>
          <p>{{ $t("contactUs.subheader") }}</p>
        </div>

        <div class="row">
          <div class="col-lg-4">
            <v-card tile flat class="infoStyle d-flex flex-column justify-content-center" data-aos="fade-right">
              <a href="https://www.google.com/maps/place/Azaleahof+Old+Age+Home/@-33.9370583,18.8629213,17z/data=!3m1!4b1!4m6!3m5!1s0x1dcdb25c963a590d:0xadfb17bcb7ecc784!8m2!3d-33.9370628!4d18.8654962!16s%2Fg%2F11b5yrbq11?entry=ttu"
                target="_blank">
                <div class="address">
                  <i class="bi bi-geo-alt"></i>
                  <h4>{{ $t("contactUs.location") }}:</h4>
                  <p>Van Riebeeckstraat 11 7600, <br> Stellenbosch, South Africa</p>
                </div>
              </a>
              <a href="mailto:azaleahof@adept.co.za" target="_blank">
                <div class="email">
                  <i class="bi bi-envelope"></i>
                  <h4>{{ $t("contactUs.email") }}:</h4>
                  <p>azaleahof@adept.co.za</p>
                </div>
              </a>
              <a href="tel:0218870136" target="_blank">
                <div class="phone">
                  <i class="bi bi-phone"></i>
                  <h4>{{ $t("contactUs.call") }}:</h4>
                  <p>021 887 0136</p>
                </div>
              </a>
              <a href="https://www.facebook.com/opnwakkeroor80/" target="_blank">
                <div class="phone">
                  <i class="bi bi-facebook"></i>
                  <h4>{{ $t("contactUs.follow") }}:</h4>
                  <p>Azaleahof ACVV Dienstak</p>
                </div>
              </a>

            </v-card>

          </div>
          <div class="col-lg-8">
            <v-card tile flat data-aos="fade-left">
              <v-card-text>
                <v-form v-model="valid" lazy-validation>
                  <v-container fluid>
                    <v-row class="mt-0">
                      <v-col cols="12" md="6">
                        <v-text-field dense outlined v-model="form.name" :rules="nameRules"
                          :placeholder="$t('contactUs.formName')" required></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field dense outlined v-model="form.email" :rules="emailRules"
                          :placeholder="$t('contactUs.formEmail')" required></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row class="mt-0">
                      <v-col cols="12" md="6">
                        <v-text-field dense outlined v-model="form.subject" :rules="subjectRules"
                          :placeholder="$t('contactUs.formSubject')" required validate-on-blur></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select item-text="name" item-value="value" required v-model="form.category"
                          :rules="categoryRules" :items="[{
                            name: $t('contactUs.formSelectOption1'),
                            value: $t('contactUs.formSelectOption1Value')
                          }, {
                            name: $t('contactUs.formSelectOption2'),
                            value: $t('contactUs.formSelectOption2Value')
                          }]" :placeholder="$t('contactUs.formSelect')" dense outlined></v-select>
                      </v-col>
                    </v-row>

                    <v-row class="mt-0">
                      <v-col cols="12">
                        <v-textarea :counter="400" dense outlined v-model="form.message" :rules="messageRules"
                          :placeholder="$t('contactUs.formMessage')" validate-on-blur></v-textarea>
                      </v-col>
                    </v-row>

                    <vue-hcaptcha ref="captcha" size="invisible"
                      sitekey="210ac117-51b0-4e59-b19f-b863c62a5b3b"></vue-hcaptcha>

                    <div class="text-center">
                      <v-btn :loading="sendingMessage" :disabled="validButton" color="#0693E3" class="mr-4 form-button"
                        large @click="validateSend">
                        {{ $t('contactUs.formButton') }}
                      </v-btn>
                    </div>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <v-card tile>
              <Map />
            </v-card>
          </div>
        </div>

      </div>
    </section><!-- End Contact Section -->
  </div>
</template>

<script>
import Map from './Map.vue'
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
export default {
  components: {
    VueHcaptcha,
    Map
  },
  data: () => ({
    token: '',
    valid: false,
    sendingMessage: false,
    form: {
      name: '',
      email: '',
      subject: '',
      message: '',
      category: ''
    },
    nameRules: [
      v => !!v || 'Name is required',
      v => v.length <= 48 || 'Name must be less than 48 characters'
    ],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
    ],
    subjectRules: [
      v => !!v || 'Subject is required',
      v => v.length >= 3 || 'Subject must be more than 3 characters'
    ],
    messageRules: [
      v => !!v || 'Message is required',
      v => v.length >= 25 || 'Message must be more than 25 characters',
      v => v.length <= 400 || 'Message must be less than 400 characters'
    ],
    categoryRules: [(v) => !!v || 'Category is required']
  }),
  computed: {
    validButton() {
      // return false
      return !this.form.message || !this.form.name || !this.form.email || !this.form.subject || !this.form.category || !this.valid
    }
  },
  mounted() {

  },
  methods: {
    async validateSend() {
      try {
        this.sendingMessage = true
        let data = await this.$refs.captcha.executeAsync()
        this.sendMessage(data.response)
      } catch (e) {
        this.sendingMessage = false
        this.$refs.captcha.reset()
        console.log(e)
      }
    },
    async sendMessage(token) {
      try {
        await this.$api.sendMessage(this.form, token)
        this.sendingMessage = false
        this.$toast.success("Message sent successfully!");
        // this.clearForm()
      } catch (e) {
        this.sendingMessage = false
        this.$toast.error("Error sending message.");
      }
    },

    clearForm() {
      this.form = {
        name: '',
        email: '',
        subject: '',
        message: '',
        category: ''
      }
    }
  }
};
</script>

<style scoped>
/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/

.contact {
  background: whitesmoke;
}

.contact h2 {
  font-family: 'IBM Plex Serif SemiBold', sans-serif;
  color: #020381;
}

.contact .infoStyle {
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
}

.contact .infoStyle i {
  font-size: 20px;
  color: #0693E3;
  float: left;
  width: 44px;
  height: 44px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .infoStyle h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #151515;
}

.contact .infoStyle p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #484848;
}

.contact .infoStyle .email,
.contact .infoStyle .phone {
  margin-top: 40px;
}

.contact .infoStyle .email:hover i,
.contact .infoStyle .address:hover i,
.contact .infoStyle .phone:hover i {
  background: #020381;
  color: #fff;
}


@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
