<template>
    <div>
        <AboutUs />
        <Services1 />
        <Services2 />
        <Services3 />
        <Portfolio @openDialog="openDialog" @closeDialog="closeDialog" />
        <Team />
        <!-- <div>
            <Organogram v-bind:properties="{
                width: 700,
                height: 600,
                data:employeeData,
                vertical: false,
                search: false,
                zoom:0
            }"
            @userSelected="handleUserSelected"
         />
        </div> -->
        <FAQ />
        <ContactUs />
        <Footer />
   
    </div>
</template>

<script>
import AboutUs from '../components/website/AboutUs.vue';
import Services1 from '../components/website/Services1.vue';
import Services2 from '../components/website/Services2.vue';
import Services3 from '../components/website/Services3.vue';
// import Featured from '../components/website/Featured.vue';
// import WhyUs from '../components/website/WhyUs.vue';
import Portfolio from '../components/website/Portfolio.vue';
import Team from '../components/website/Team.vue';
import FAQ from '../components/website/FAQ.vue';
import ContactUs from '../components/website/ContactUs.vue';
import Footer from '../components/website/Footer.vue';


export default {
    components: {
        AboutUs,
        Services1,
        Services2,
        Services3,
        // Featured,
        // WhyUs,
        Portfolio,
        Team,
        FAQ,
        ContactUs,
        Footer,
    },
    data: () => ({


    }),
    mounted() {
    },
    methods: {
        openDialog() {
            var header = document.getElementById('header');
            header.classList.remove('fixed');
        },
        closeDialog() {
            var header = document.getElementById('header');

            header.classList.add('fixed');
        },
 
    }
};


</script>

<style>
/* .locale-switcher {
    position: fixed;
    visibility: visible;
    opacity: 1;
    left: 15px;
    top: 15px;
    z-index: 995;
    border-radius: 4px;
    transition: all 0.4s;
} */
</style>