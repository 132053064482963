<template>
    <v-card flat data-aos="fade-up">
        <GmapMap :center="center" :zoom="17" map-type-id="terrain" class="map">
            <GmapMarker :key="index" v-for="(m, index) in markers" :position="m.position" :clickable="true"
                :draggable="true" @click="centerMap(m.position)" />
        </GmapMap>
    </v-card>
</template>
  
<script>
export default {
    props: {

    },
    data() {
        return {
            center: { lat: -33.936961942120114, lng: 18.86529545966135 },
            markers: [
                { position: { lat: -33.936961942120114, lng: 18.86529545966135 }, name: 'Azaleahof' },
                // { position: { lat: -33.93769279854613, lng: 18.86385984209788 }, name: 'Moederkerk/Kruiskerk', description: 'Description for Marker 2' },

            ]
        };
    },
    watch: {

    },
    methods: {
        centerMap(position) {
            this.center = position;
        },
    }
};
</script>
<style scoped>
.map {

    height: 500px
}
</style>