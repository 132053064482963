<template>
  <div>
    <!-- ======= Frequently Asked Questions Section ======= -->
    <section id="faq" class="faq">
      <div class="container">

        <div class="section-title" data-aos="fade-up">
          <h2>{{ $t("faq.header") }}</h2>
        </div>

        <v-card flat>
          <v-card-text>
            <ul class="faq-list">
              <li data-aos="fade-up">
                <a data-bs-toggle="collapse" class="collapsed" data-bs-target="#faq1">{{ $t("faq.question1") }} <i
                    class="bx bx-chevron-down icon-show"></i><i class="bx bx-x icon-close"></i></a>
                <div id="faq1" class="collapse" data-bs-parent=".faq-list">
                  <p>
                    {{ $t("faq.answer1") }}
                  </p>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="10">
                <a data-bs-toggle="collapse" data-bs-target="#faq2" class="collapsed">{{ $t("faq.question2") }} <i
                    class="bx bx-chevron-down icon-show"></i><i class="bx bx-x icon-close"></i></a>
                <div id="faq2" class="collapse" data-bs-parent=".faq-list">
                  <p>
                    {{ $t("faq.answer2") }}
                  </p>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="20">
                <a data-bs-toggle="collapse" data-bs-target="#faq3" class="collapsed">{{ $t("faq.question3") }} <i
                    class="bx bx-chevron-down icon-show"></i><i class="bx bx-x icon-close"></i></a>
                <div id="faq3" class="collapse" data-bs-parent=".faq-list">
                  <p>
                    {{ $t("faq.answer3") }}
                  </p>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="30">
                <a data-bs-toggle="collapse" data-bs-target="#faq4" class="collapsed">{{ $t("faq.question4") }} <i
                    class="bx bx-chevron-down icon-show"></i><i class="bx bx-x icon-close"></i></a>
                <div id="faq4" class="collapse" data-bs-parent=".faq-list">
                  <p>
                    {{ $t("faq.answer4") }}
                  </p>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="40">
                <a data-bs-toggle="collapse" data-bs-target="#faq5" class="collapsed">{{ $t("faq.question5") }} <i
                    class="bx bx-chevron-down icon-show"></i><i class="bx bx-x icon-close"></i></a>
                <div id="faq5" class="collapse" data-bs-parent=".faq-list">
                  <p>
                    {{ $t("faq.answer5") }}
                  </p>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="50">
                <a data-bs-toggle="collapse" data-bs-target="#faq6" class="collapsed">{{ $t("faq.question6") }} <i
                    class="bx bx-chevron-down icon-show"></i><i class="bx bx-x icon-close"></i></a>
                <div id="faq6" class="collapse" data-bs-parent=".faq-list">
                  <p>
                    {{ $t("faq.answer6") }}
                  </p>
                </div>
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </div>
    </section><!-- End Frequently Asked Questions Section -->
  </div>
</template>

<script>
export default {
  data: () => ({
    //
  }),
};
</script>

<style scoped>
/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/

.faq {
  background: white;
}

.faq h2 {
  font-family: 'IBM Plex Serif SemiBold', sans-serif;
  color: #020381;
}

.faq .faq-list {
  padding: 0;
  list-style: none;
}

.faq .faq-list li {
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.faq .faq-list a {
  display: block;
  position: relative;
  font-family: #020381;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  padding-right: 25px;
  cursor: pointer;
}

.faq .faq-list i {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.faq .faq-list .icon-show {
  display: none;
}

.faq .faq-list a.collapsed {
  color: #343a40;
}

.faq .faq-list a.collapsed:hover {
  color: #020381;
}

.faq .faq-list a.collapsed .icon-show {
  display: inline-block;
}

.faq .faq-list a.collapsed .icon-close {
  display: none;
}
</style>