<template>
    <v-container data-app="true">
        <v-row>
            <v-col v-for="(image, index) in images" :key="index" :md="mdCols" :sm="smCols" :cols="xsCols" :lg="lgCols">
                <v-card>
                    <v-img eager :src="image.srcs" @click.stop="openCarousal(image)" aspect-ratio="1">
                        <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="carousalDialog" :max-width="dialogWidth" max-height="85vh">
            <v-card class="mx-auto" :max-width="cardWidth" v-if="activeImage" contain>
                <v-toolbar flat dense>
                    <v-toolbar-title>
                        {{ activeImage.description }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon text @click="closeCarousal">
                        <v-icon color="red">close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-img eager height="auto" max-height="80vh" :src="activeImage.src" :key="imgKey">
                    <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate></v-progress-circular>
                        </v-row>
                    </template>
                    <v-row>
                        <v-col cols="4">
                            <v-btn @click="prevImage()" text block :height="buttonHeight">
                                <v-icon color="white">arrow_back_ios</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="4">
                            <v-btn @click="handleFullscreen" text block :height="buttonHeight">
                                <v-icon color="white">visibility</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="4">
                            <v-btn @click="nextImage()" text block :height="buttonHeight">
                                <v-icon color="white">arrow_forward_ios</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-img>
            </v-card>
        </v-dialog>

        <v-dialog fullscreen v-model="fullScreenImage" :key="fullscreenKey" contain v-if="activeImage">
            <v-overlay :opacity="1">
                <v-img eager :aspect-ratio="getAspectRatio(activeImage)" width="100vw" height="auto" max-height="100vh"
                    :src="activeImage.src">
                    <v-toolbar flat style="background-color: transparent !important;">
                        <v-spacer></v-spacer>
                        <v-btn text icon color="white" @click="handleFullscreen">
                            <v-icon color="red">close</v-icon>
                        </v-btn>
                    </v-toolbar>
                </v-img>
            </v-overlay>
        </v-dialog>
    </v-container>
</template>
<script>
export default {

    props: {
        images: {
            type: Array
        },
        imageWidth: {
            type: Number,
            default: 720
        },
        imageHeight: {
            type: Number || String,
            default: "auto"
        },
        popUpMaxWidth: {
            type: Number,
            default: 720
        },
        lgCols: {
            type: Number,
            default: 2
        },
        mdCols: {
            type: Number,
            default: 4
        },
        smCols: {
            type: Number,
            default: 4
        },
        xsCols: {
            type: Number,
            default: 6
        }
    },
    data() {
        return {
            carousalDialog: false,
            carouselIndex: 0,
            activeImage: null,
            dialogWidth: '70vw',
            cardWidth: '70vw',
            buttonHeight: '80vh',
            fullscreenKey: 0,
            fullScreenImage: false,
            imgKey: 9000
        };
    },
    mounted() {

    },
    created() {
        this.adjustDialogSize();
        window.addEventListener('resize', this.adjustDialogSize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.adjustDialogSize);
    },
    methods: {
        adjustDialogSize() {
            if (window.innerWidth < 600) {
                this.dialogWidth = '95vw';
                this.cardWidth = '95vw';
                this.buttonHeight = '50vh';
            } else {
                this.dialogWidth = '70vw';
                this.cardWidth = '70vw';
                this.buttonHeight = '80vh';
            }
        },
        openCarousal(selectedImage) {
            const index = this.images.findIndex(image => image.src === selectedImage.src)
            this.carouselIndex = index
            this.activeImage = selectedImage
            this.carousalDialog = true
        },
        closeCarousal() {
            this.carouselIndex = 0
            this.activeImage = null
            this.carousalDialog = false
        },
        nextImage() {
            if (this.carouselIndex === this.images.length - 1) {
                this.carouselIndex = 0
                this.activeImage = this.images[this.carouselIndex]
            } else {
                this.carouselIndex++
                this.activeImage = this.images[this.carouselIndex]
            }
            this.imgKey++
        },
        prevImage() {
            if (this.carouselIndex === 0) {
                this.carouselIndex = this.images.length - 1
                this.activeImage = this.images[this.carouselIndex]
            } else {
                this.carouselIndex--
                this.activeImage = this.images[this.carouselIndex]
            }
            this.imgKey++
        },
        handleFullscreen() {
            this.fullScreenImage = !this.fullScreenImage
            this.fullscreenKey++
        },
        getAspectRatio(image) {
            return image.width / image.height;
        },
    }
};
</script>
<style scoped></style>
