<template>
  <div>
    <section id="team" class="team">
      <v-container>

        <div class="section-title" data-aos="fade-up">
          <h2>{{ $t("team.header") }}</h2>

          <p>{{ $t("team.subheader") }}</p>
          <v-btn large text plain class="section-subtitle mt-2" color="primary" @click="openOrganogram">{{
            $t("team.subtitle") }} <v-icon right>account_tree</v-icon> </v-btn>
        </div>

        <v-row>
          <v-col cols="12" sm="6" md="6" lg="3" xl="3" v-for="member in teamMembers" :key="member.id">
            <v-card flat tile class="image-card center-span-container" data-aos="zoom-in">
              <v-card>
                <v-img aspect-ratio="0.75" :src="member.photo" height="auto"></v-img>
              </v-card>

              <v-card-title style="color: #020381;">{{ member.name }}</v-card-title>
              <v-card-subtitle>{{ member.role }}</v-card-subtitle>
              <v-card-text class="text--truncate">
                <p>{{ member.description }}</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-dialog v-model="orgModel" fullscreen>
        <v-card class="mx-auto orgDialog">
          <v-toolbar flat color="rgba(245, 245, 245)">
            <v-toolbar-title>
              <div class="section-subtitle" color="primary">
              <h2>{{ $t("team.subtitle") }}</h2>
            </div>
            </v-toolbar-title>

            <v-spacer></v-spacer>
            <v-btn icon text @click="orgModel = false">
              <v-icon color="red">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text outlined class="mx-auto">
            <v-card width="100vw" flat >
              <v-layout row justify-center>
                <Organogram />
              </v-layout>
            </v-card>
          </v-card-text>
        </v-card>

      </v-dialog>
    </section>
  </div>
</template>

<script>
import Organogram from "../website/Organogram.vue";;
export default {
  components: {
    Organogram,
  },
  data: () => ({
    teamMembers: [],
    userViewModal: false,
    selectedUser: {},
    orgModel: false,
  }),
  created() {

  },
  mounted() {
    this.setTeamMembers()
  },
  watch: {
    '$i18n.locale'() {
      this.teamMembers = []
      this.setTeamMembers()
    },
    orgModel(val){
      this.$fullscreen = val
    }
  },
  computed: {
    cardHeight() {
      return window.innerHeight * 0.9;
    },
    imageHeight() {
      return window.innerHeight * 0.5;
    }
  },
  methods: {
    setTeamMembers() {
      for (let i = 1; i <= 4; i++) {
        this.teamMembers.push({
          id: i,
          name: this.$t(`team.member${i}Name`),
          role: this.$t(`team.member${i}Role`),
          description: this.$t(`team.member${i}Description`),
          photo: require(`@/assets/img/team/team-${i}.webp`)
        })
      }
    },
    openOrganogram() {
      this.orgModel = true
    },
    handleUserSelected(user) {
      this.selectedUser = user;
      this.userViewModal = true;
    },
    handleCloseUserModel() {
      this.selectedUser = {};
      this.userViewModal = false;
    },
  },
};
</script>

<style scoped>
/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.orgDialog {
  background: whitesmoke;
}

.image {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.image-container {
  background: whitesmoke;
  width: 100%;
  height: 95vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-subtitle {
  color: #020381;
}

.center-span-container {
  align-items: center;
  height: 100%;
}

.team {
  background: whitesmoke;
  padding: 60px 0;
}

.team h2 {
  font-family: 'IBM Plex Serif SemiBold', sans-serif;
  color: #020381;
}

.team .member {
  margin-bottom: 20px;
  overflow: hidden;
}

.team .member .member-img {
  position: relative;
  overflow: hidden;
  border-radius: 5px;

}

.team .member .social {
  position: absolute;
  left: 0;
  bottom: -40px;
  right: 0;
  height: 40px;
  opacity: 0;
  transition: bottom ease-in-out 0.4s;
  background: #ABB8C3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team .member .social a {
  transition: color 0.3s;
  color: rgba(255, 255, 255, 0.7);
  margin: 0 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.team .member .social a i {
  line-height: 0;
}

.team .member .social a:hover {
  color: #fff;
}

.team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin: 15px 0 5px 0;
  font-size: 18px;
}

.team .member .member-info span {
  display: block;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 15px;
  color: #020381;
}

.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #888888;
}

.team .member:hover .social {
  bottom: 0;
  opacity: 1;
  transition: bottom ease-in-out 0.4s;
}


/* Slider */
.slider-container {
  position: relative;
}

.slider-container button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: #333;
  /* Adjust the color as needed */
  cursor: pointer;
  transition: color 0.3s ease;
}

.slider-container button:hover {
  color: #007bff;
  /* Adjust the hover color as needed */
}

.slider-container button[data-glide-dir="<"] {
  left: -30px;
  /* Adjust the left offset as needed */
}

.slider-container button[data-glide-dir=">"] {
  right: -30px;
  /* Adjust the right offset as needed */
}
</style>