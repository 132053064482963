<template>
  <div>
    <!-- ======= Footer ======= -->
    <footer id="footer">

      <div class="footer-top">

        <div class="container">

          <div class="row justify-content-center">
            <div class="col-lg-6">
              
              <a href="#header" class="scrollto"><v-img eager contain max-height="30vh" src="@/assets/img/logo_rounded_large.png"></v-img></a>
              <h3>{{ $t("footer.header") }}</h3>
              <p>{{ $t("footer.subheader") }}</p>
            </div>
          </div>
        </div>
      </div>

    </footer><!-- End Footer -->
  </div>
</template>

<script>
export default {
  data: () => ({
    // formEmail: this.$t("footer.email"),
    // emailButton: this.$t("footer.emailButton"),
  }),
};
</script>

<style scoped>
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

#footer::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.50);
  z-index: 1;
}

#footer .footer-top {
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 80px 0;
}

#footer-logo img {
  height: 20px;
}

#footer .footer-top h3 {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
  position: relative;
  font-family: 'IBM Plex Serif SemiBold', sans-serif;
  padding: 30px 0 0 0;
  margin-bottom: 0;
}

#footer .footer-top p {
  font-size: 15;
  font-style: italic;
  margin: 30px 0 0 0;
  padding: 0;
}

#footer .footer-top .footer-newsletter {
  text-align: center;
  font-size: 15px;
  margin-top: 30px;
}

#footer .footer-top .footer-newsletter form {
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 50px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  text-align: left;
}

#footer .footer-top .footer-newsletter form input[type=email] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}

#footer .footer-top .footer-newsletter form input[type=submit] {
  position: absolute;
  top: 0;
  right: -1px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #0693E3;
  color: #fff;
  transition: 0.3s;
  border-radius: 50px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-top .footer-newsletter form input[type=submit]:hover {
  background: #020381;
}

#footer .footer-top .social-links {
  margin-top: 30px;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #020381;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #0693E3;
  color: #fff;
  text-decoration: none;
}

#footer .footer-bottom {
  border-top: 1px solid #222222;
  z-index: 2;
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
}

#footer .copyright {
  text-align: center;
}

#footer .credits {
  text-align: center;
  font-size: 13px;
  padding-top: 5px;
}
</style>
