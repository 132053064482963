<template>
    <v-card rounded outlined flat>
        <div ref="chartContainer"> </div>
    </v-card>

</template>
<script>
import { OrgChart } from 'd3-org-chart';
export default {
    name: 'Chart',
    data() {
        return {
            chart: null,
            data: null,
        };
    },
    created() {
        this.data = this.$employees
    },
    mounted() {
        setTimeout(() => {
            this.renderChart(this.data);
        }, 200)
    },
    methods: {
        renderChart(data) {
            if (!this.chart) {
                this.initChart();
            }
            this.chart
                .data(data)
                .render();
        },
        initChart(data) {
            this.chart = new OrgChart();
            this.chart
                .container(this.$refs.chartContainer)
                .data(data)
                .initialZoom(1)
                .nodeContent(function (d, i, arr, state) {
                    return `<div style="font-family: 'Inter', sans-serif;background-color:${d.data.color}; position:absolute;margin-top:-1px; margin-left:-1px;width:${d.width}px;height:${d.height}px;border-radius:10px;border: 1px solid #E4E2E9">
                                <div style="color:#08011E;position:absolute;right:20px;top:17px;font-size:10px;"><i class="fas fa-ellipsis-h"></i></div>
                                <div style="font-size:15px;color:#08011E;margin-left:20px;margin-top:32px"> ${d.data.name} ${d.data.size && d.data.size > 1 ? `(${d.data.size})` : ''} </div>
                                <div style="color:#716E7B;margin-left:20px;margin-top:3px;font-size:10px;"> ${d.data.positionName ?? ''} </div>
                                </div>
                                    <span>${d}</span>
                                `;
                })
                .render();

        },
        renderAndUpdateChart() {
            if (this.chart) {
                const width = this.$refs.chartContainer.clientWidth;
                const height = this.$refs.chartContainer.clientHeight;

                this.chart
                    .svgWidth(width)
                    .svgHeight(height)
                    .render();
            } else {
                this.initChart();
                this.chart.render();
            }
        },
    },
};
</script>

<style scoped></style>