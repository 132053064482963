<template>
  <v-app v-resize="onResize">
    <div>
      <Hero />
      <Header />
      <v-main id="main">
        <Home />

        <vue-cookie-accept-decline :debug="false" :disableDecline="false" :showPostponeButton="false"
        elementId="myPanel1" position="bottom-left" ref="myPanel1" transitionName="slideFromBottom" type="floating">
      </vue-cookie-accept-decline>
      <!-- <vue-cookie-accept-decline :debug="false" :disableDecline="false" :showPostponeButton="false"
        @clicked-accept="cookieClickedAccept" @clicked-decline="cookieClickedDecline"
        @clicked-postpone="cookieClickedPostpone" @removed-cookie="cookieRemovedCookie" @status="cookieStatus"
        elementId="myPanel1" position="bottom-left" ref="myPanel1" transitionName="slideFromBottom" type="floating">
      </vue-cookie-accept-decline> -->
      </v-main>
    </div>
  </v-app>
  
</template>

<script>
import Home from './views/HomeView.vue';
import Hero from '@/components/website/Hero.vue';
import Header from '@/components/website/Header.vue';
export default {
  name: 'App',

  components: {
    Home,
    Hero,
    Header
  },

  data: () => ({
  }),
  mounted() {

  },
  methods: {
  }
};
</script>

<style>


/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "Open Sans", sans-serif;
  color: #555555;
}

a {
  text-decoration: none;
  color: #020381;
}

a:hover {
  color: #0693E3;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}

h2 {
  font-family: 'IBM Plex Serif SemiBold', sans-serif;
}

/*--------------------------------------------------------------
# Disable AOS delay on mobile
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: whitesmoke;
}

.section-title {
  text-align: center;
  padding-bottom: 40px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 15px;
  padding-bottom: 0;
  color: #151515;
}

.section-title p {
  margin-bottom: 0;
  color: #aeaeae;
}


/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #0693E3;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #020381;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}


/*--------------------------------------------------------------
# ACVV Top Logo
--------------------------------------------------------------*/

.acvv-logo::-ms-ticks-before {
  visibility: hidden;
}

.acvv-logo {
  position: fixed;
  visibility: visible;
  opacity: 1;
  right: 15px;
  bottom: 15px;
  z-index: 995;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.acvv-logo img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.acvv-logo.hidden {
  visibility: hidden;
  opacity: 0;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 100vh;
  background: url("@/assets/img/hero-bg.webp") center center no-repeat;
  position: relative;
  background-size: cover;
  backdrop-filter: blur(2px);
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: url("@/assets/img/footer-bg.webp") center center no-repeat;
  color: #fff;
  font-size: 14px;
  position: relative;
  background-size: cover;
}

.v-carousel__controls__item {
  background: red; /* Change 'red' to your desired background color */
}
</style>
