import axios from "axios";

let url = "https://api.azaleahof.co.za"
// let url = "http://test.mk-ultra.co.za:3090"
// let url = "http://10.242.158.117:3090"

let api = axios.create({
    baseURL: url,
    maxContentLength: 100000000,
    maxBodyLength: 1000000000,
});

export default {

    async sendMessage(obj, token) {
        let res = await api.post(`/sendMessage`,  obj, { headers: { 'Authorization': token }});
        return res.data
    },
}