<template>
  <div>

    <section id="portfolio" class="portfolio">
      <v-container>
        <v-col :cols="12">
          <div class="section-title" data-aos="fade-up">
            <h2>{{ $t("gallery.header") }}</h2>
            <p>{{ $t("gallery.subheader") }}</p>
          </div>
          <v-container fluid data-aos="fade-up">
            <v-row class="mt-2">
              <v-col :cols="12">
                <v-card class="gallery-card mx-auto" tile>
                  <v-card-title dense>
                    <v-btn-toggle light group mandatory color="#0693E3" v-model="selectedAlbum" @change="selectAlbum">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" text :value="1"><v-icon>apartment</v-icon></v-btn>
                        </template>
                        <span>{{ $t("gallery.album1") }}</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" text :value="2"><v-icon>bedroom_parent</v-icon></v-btn>
                        </template>
                        <span>{{ $t("gallery.album2") }}</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" text :value="3"><v-icon>groups</v-icon></v-btn>
                        </template>
                        <span>{{ $t("gallery.album3") }}</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" text :value="4"><v-icon>medication_liquid</v-icon></v-btn>
                        </template>
                        <span>{{ $t("gallery.album4") }}</span>
                      </v-tooltip>
                    </v-btn-toggle>
                    <v-spacer></v-spacer>
                    <v-btn icon text @click="openDialog"><v-icon>fullscreen</v-icon></v-btn>
                  </v-card-title>
                  <v-card-text class="gallery-container">
                    <Gallery :images="filteredImages" :imageWidth="720" :imageHeight="-1" :popUpMaxWidth="720"
                      :mdCols="4" :smCols="4" :xsCols="6" :lgCols="3" />
                  </v-card-text>
                  <v-card-actions>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-container>

    </section><!-- End Portfolio Section -->

    <v-layout row justify-center>
      <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
        <v-card tile class="gallery-card">
          <v-card-title dense>
            <v-btn-toggle light group mandatory color="#0693E3" v-model="selectedAlbum" @change="selectAlbum">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text :value="1"><v-icon>apartment</v-icon></v-btn>
                </template>
                <span>{{ $t("gallery.album1") }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text :value="2"><v-icon>bedroom_parent</v-icon></v-btn>
                </template>
                <span>{{ $t("gallery.album2") }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text :value="3"><v-icon>groups</v-icon></v-btn>
                </template>
                <span>{{ $t("gallery.album3") }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text :value="4"><v-icon>medication_liquid</v-icon></v-btn>
                </template>
                <span>{{ $t("gallery.album4") }}</span>
              </v-tooltip>
            </v-btn-toggle>
            <v-spacer></v-spacer>
            <v-btn icon text @click="closeDialog"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-card-text class="gallery-container-modal">
            <Gallery :images="filteredImages" :imageWidth="720" :imageHeight="-1" :popUpMaxWidth="720" :mdCols="4"
              :smCols="4" :xsCols="6" :lgCols="3" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>

  </div>
</template>

<script>
import Gallery from "../website/Gallery.vue";
export default {
  components: {
    Gallery
  },
  data: () => ({
    dialog: false,
    filteredImages: [],
    selectedAlbum: 1,
    imagesAfrikaans: [
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-001-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-001.webp'), albumId: 1, id: 1, description: 'Ontvangs' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-002-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-002.webp'), albumId: 1, id: 2, description: 'Braaiarea in ons agterplaas' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-003-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-003.webp'), albumId: 1, id: 3, description: 'Azaleahof se sonpaneel-installasie' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-004-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-004.webp'), albumId: 1, id: 4, description: 'Dienssentrum saal' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-005-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-005.webp'), albumId: 1, id: 5, description: 'Vloerportaal met huiser en sithoekie' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-006-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-006.webp'), albumId: 1, id: 6, description: 'Gedekte tafels in eetsaal met uitsig op die tuin' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-007-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-007.webp'), albumId: 1, id: 7, description: 'Eetsaal met gedekte tafels' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-008-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-008.webp'), albumId: 1, id: 8, description: 'Klavier in dienssentrum saal' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-009-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-009.webp'), albumId: 1, id: 9, description: 'Tuin met sitruimte vir buite etes' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-010-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-010.webp'), albumId: 1, id: 10, description: 'Blomme in tuin onderhou deur verskeie inwoners en personeel.' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-011-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-011.webp'), albumId: 1, id: 11, description: 'Agterplaas met mooi tuin' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-012-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-012.webp'), albumId: 1, id: 12, description: 'Sitruimte vir buite etes by die eetsaal vensters' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-013-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-013.webp'), albumId: 1, id: 13, description: 'Agterplaas met tuin en braaiarea' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-014-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-014.webp'), albumId: 1, id: 1, description: 'Eerstevloer se buite sitruimte' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-015-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-015.webp'), albumId: 1, id: 2, description: 'Haarsalon' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-016-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-016.webp'), albumId: 1, id: 3, description: 'Inwoners se parkeerarea' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-017-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-017.webp'), albumId: 1, id: 4, description: 'Lieflike kagel in eetsaal' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-018-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-018.webp'), albumId: 1, id: 5, description: 'Lugfoto van Azaleahof en omgewing' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-019-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-019.webp'), albumId: 1, id: 6, description: 'Sitruimte in eetsaal' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-020-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-020.webp'), albumId: 1, id: 7, description: 'Plante in ons binnehof' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-021-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-021.webp'), albumId: 1, id: 8, description: 'Plante in ons binnehof' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-022-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-022.webp'), albumId: 1, id: 9, description: 'Azaleahof agterplaas van bo' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-001-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-001.webp'), albumId: 2, id: 14, description: 'Dubbelkamer - leefarea' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-002-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-002.webp'), albumId: 2, id: 15, description: 'Versierings' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-003-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-003.webp'), albumId: 2, id: 16, description: 'Versierings' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-004-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-004.webp'), albumId: 2, id: 17, description: 'Dubbelkamer - slaapkamer' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-005-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-005.webp'), albumId: 2, id: 18, description: 'Enkelkamer - kombuishoekie' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-006-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-006.webp'), albumId: 2, id: 19, description: 'Mansions woonstel - kombuis' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-007-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-007.webp'), albumId: 2, id: 20, description: 'Enkelkamer' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-008-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-008.webp'), albumId: 2, id: 21, description: 'Mansions woonstel - portaal' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-009-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-009.webp'), albumId: 2, id: 22, description: 'Enkelkamer met uitsig van berg' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-010-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-010.webp'), albumId: 2, id: 23, description: 'Mansions woonstel - ruim sitkamer' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-011-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-011.webp'), albumId: 2, id: 14, description: 'Enkelkamer - ingeboude kaste' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-012-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-012.webp'), albumId: 2, id: 15, description: 'Mansions woonstel - slaapkamer' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-013-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-013.webp'), albumId: 2, id: 16, description: 'Enkelkamer - kombuishoekie' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-014-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-014.webp'), albumId: 2, id: 17, description: 'Enkelkamer' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-015-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-015.webp'), albumId: 2, id: 18, description: 'Enkelkamer - volle badkamer' },
      { srcs:require('@/assets/img/gallery/staff/staff-001-s.webp'), src: require('@/assets/img/gallery/staff/staff-001.webp'), albumId: 3, id: 29, description: 'Sorgeenheid versorger en admin' },
      { srcs:require('@/assets/img/gallery/staff/staff-002-s.webp'), src: require('@/assets/img/gallery/staff/staff-002.webp'), albumId: 3, id: 29, description: 'Sorgeenheid versorger' },
      { srcs:require('@/assets/img/gallery/staff/staff-003-s.webp'), src: require('@/assets/img/gallery/staff/staff-003.webp'), albumId: 3, id: 30, description: 'Sorgeenheid versorger' },
      { srcs:require('@/assets/img/gallery/staff/staff-004-s.webp'), src: require('@/assets/img/gallery/staff/staff-004.webp'), albumId: 3, id: 31, description: 'Sorgeenheid versorger en Suster' },
      { srcs:require('@/assets/img/gallery/staff/staff-005-s.webp'), src: require('@/assets/img/gallery/staff/staff-005.webp'), albumId: 3, id: 32, description: 'Ontvangsdame' },
      { srcs:require('@/assets/img/gallery/staff/staff-006-s.webp'), src: require('@/assets/img/gallery/staff/staff-006.webp'), albumId: 3, id: 33, description: 'Kombuis' },
      { srcs:require('@/assets/img/gallery/staff/staff-007-s.webp'), src: require('@/assets/img/gallery/staff/staff-007.webp'), albumId: 3, id: 29, description: 'Kombuis' },
      { srcs:require('@/assets/img/gallery/staff/staff-008-s.webp'), src: require('@/assets/img/gallery/staff/staff-008.webp'), albumId: 3, id: 29, description: 'Kombuis' },
      { srcs:require('@/assets/img/gallery/staff/staff-009-s.webp'), src: require('@/assets/img/gallery/staff/staff-009.webp'), albumId: 3, id: 29, description: 'Wassery' },
      { srcs:require('@/assets/img/gallery/staff/staff-010-s.webp'), src: require('@/assets/img/gallery/staff/staff-010.webp'), albumId: 3, id: 29, description: 'Wassery' },
      { srcs:require('@/assets/img/gallery/service-centre/service-centre-001-s.webp'), src: require('@/assets/img/gallery/service-centre/service-centre-001.webp'), albumId: 4, id: 39, description: 'Sorgeenheid ingang op eerstevloer' },
      { srcs:require('@/assets/img/gallery/service-centre/service-centre-002-s.webp'), src: require('@/assets/img/gallery/service-centre/service-centre-002.webp'), albumId: 4, id: 40, description: 'Eetsaal met gedekte tafels' },
      { srcs:require('@/assets/img/gallery/service-centre/service-centre-003-s.webp'), src: require('@/assets/img/gallery/service-centre/service-centre-003.webp'), albumId: 4, id: 41, description: 'Bed in dubbelkamer' },
      { srcs:require('@/assets/img/gallery/service-centre/service-centre-004-s.webp'), src: require('@/assets/img/gallery/service-centre/service-centre-004.webp'), albumId: 4, id: 42, description: 'Bed in dubbelkamer' },
      { srcs:require('@/assets/img/gallery/service-centre/service-centre-005-s.webp'), src: require('@/assets/img/gallery/service-centre/service-centre-005.webp'), albumId: 4, id: 43, description: 'Enkelkamer' }
    ],
    imagesEnglish: [
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-001-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-001.webp'), albumId: 1, id: 1, description: 'Reception' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-002-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-002.webp'), albumId: 1, id: 2, description: 'Braai area' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-003-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-003.webp'), albumId: 1, id: 3, description: 'Azaleahof solar panel installation' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-004-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-004.webp'), albumId: 1, id: 4, description: 'Service centre hall' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-005-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-005.webp'), albumId: 1, id: 5, description: 'Elevator lobby with seating area' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-006-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-006.webp'), albumId: 1, id: 6, description: 'Dining hall with view of the garden' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-007-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-007.webp'), albumId: 1, id: 7, description: 'Dining hall' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-008-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-008.webp'), albumId: 1, id: 8, description: 'Piano in service centre hall' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-009-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-009.webp'), albumId: 1, id: 9, description: 'Seating in garden to enjoy meals outside' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-010-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-010.webp'), albumId: 1, id: 10, description: 'Flowers in our garden' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-011-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-011.webp'), albumId: 1, id: 11, description: 'Garden in the backyard' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-012-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-012.webp'), albumId: 1, id: 12, description: 'Outside seating area next to dining hall windows' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-013-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-013.webp'), albumId: 1, id: 13, description: 'Garden and braai area in backyard' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-014-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-014.webp'), albumId: 1, id: 1, description: 'First floor outside seating area' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-015-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-015.webp'), albumId: 1, id: 2, description: 'Hair salon' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-016-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-016.webp'), albumId: 1, id: 3, description: 'Parkingarea for residents' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-017-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-017.webp'), albumId: 1, id: 4, description: 'Lovely fireplace in dining hall' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-018-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-018.webp'), albumId: 1, id: 5, description: 'Aerial photo of Azaleahof and surroundings' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-019-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-019.webp'), albumId: 1, id: 6, description: 'Seating area in dining hall' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-020-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-020.webp'), albumId: 1, id: 7, description: 'Inner courtyard garden' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-021-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-021.webp'), albumId: 1, id: 8, description: 'Inner courtyard garden' },
      { srcs:require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-022-s.webp'), src: require('@/assets/img/gallery/buildings-and-facilities/buildings-and-facilities-022.webp'), albumId: 1, id: 9, description: 'Azaleahof backyard from above' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-001-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-001.webp'), albumId: 2, id: 14, description: 'Double room - living room' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-002-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-002.webp'), albumId: 2, id: 15, description: 'Decorations' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-003-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-003.webp'), albumId: 2, id: 16, description: 'Decorations' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-004-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-004.webp'), albumId: 2, id: 17, description: 'Double room - bedroom' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-005-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-005.webp'), albumId: 2, id: 18, description: 'Single room - kitchenette' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-006-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-006.webp'), albumId: 2, id: 19, description: 'Mansions apartment - kitchen' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-007-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-007.webp'), albumId: 2, id: 20, description: 'Single room' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-008-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-008.webp'), albumId: 2, id: 21, description: 'Mansions apartment - foyer' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-009-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-009.webp'), albumId: 2, id: 22, description: 'Single room with view of mountain' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-010-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-010.webp'), albumId: 2, id: 23, description: 'Mansions apartment - living room' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-011-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-011.webp'), albumId: 2, id: 14, description: 'Single room - built-in cupboards' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-012-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-012.webp'), albumId: 2, id: 15, description: 'Mansions ampartment - bedroom' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-013-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-013.webp'), albumId: 2, id: 16, description: 'Single room - kitchenette' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-014-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-014.webp'), albumId: 2, id: 17, description: 'Single room' },
      { srcs:require('@/assets/img/gallery/rooms/rooms-015-s.webp'), src: require('@/assets/img/gallery/rooms/rooms-015.webp'), albumId: 2, id: 18, description: 'Single room - bathroom' },
      { srcs:require('@/assets/img/gallery/staff/staff-001-s.webp'), src: require('@/assets/img/gallery/staff/staff-001.webp'), albumId: 3, id: 28, description: 'Frail care unit carer and admin' },
      { srcs:require('@/assets/img/gallery/staff/staff-002-s.webp'), src: require('@/assets/img/gallery/staff/staff-002.webp'), albumId: 3, id: 29, description: 'Frail care unit carer' },
      { srcs:require('@/assets/img/gallery/staff/staff-003-s.webp'), src: require('@/assets/img/gallery/staff/staff-003.webp'), albumId: 3, id: 30, description: 'Frail care unit carer' },
      { srcs:require('@/assets/img/gallery/staff/staff-004-s.webp'), src: require('@/assets/img/gallery/staff/staff-004.webp'), albumId: 3, id: 31, description: 'Frail care unit carer and Sister' },
      { srcs:require('@/assets/img/gallery/staff/staff-005-s.webp'), src: require('@/assets/img/gallery/staff/staff-005.webp'), albumId: 3, id: 32, description: 'Receptionist' },
      { srcs:require('@/assets/img/gallery/staff/staff-006-s.webp'), src: require('@/assets/img/gallery/staff/staff-006.webp'), albumId: 3, id: 33, description: 'Kitchen' },
      { srcs:require('@/assets/img/gallery/staff/staff-007-s.webp'), src: require('@/assets/img/gallery/staff/staff-007.webp'), albumId: 3, id: 29, description: 'Kitchen' },
      { srcs:require('@/assets/img/gallery/staff/staff-008-s.webp'), src: require('@/assets/img/gallery/staff/staff-008.webp'), albumId: 3, id: 29, description: 'Kitchen' },
      { srcs:require('@/assets/img/gallery/staff/staff-009-s.webp'), src: require('@/assets/img/gallery/staff/staff-009.webp'), albumId: 3, id: 29, description: 'Laundry facility' },
      { srcs:require('@/assets/img/gallery/staff/staff-010-s.webp'), src: require('@/assets/img/gallery/staff/staff-010.webp'), albumId: 3, id: 29, description: 'Laundry facility' },
      { srcs:require('@/assets/img/gallery/service-centre/service-centre-001-s.webp'), src: require('@/assets/img/gallery/service-centre/service-centre-001.webp'), albumId: 4, id: 39, description: 'Frail care unit entrance' },
      { srcs:require('@/assets/img/gallery/service-centre/service-centre-002-s.webp'), src: require('@/assets/img/gallery/service-centre/service-centre-002.webp'), albumId: 4, id: 40, description: 'Dining hall' },
      { srcs:require('@/assets/img/gallery/service-centre/service-centre-003-s.webp'), src: require('@/assets/img/gallery/service-centre/service-centre-003.webp'), albumId: 4, id: 41, description: 'Bed in double room' },
      { srcs:require('@/assets/img/gallery/service-centre/service-centre-004-s.webp'), src: require('@/assets/img/gallery/service-centre/service-centre-004.webp'), albumId: 4, id: 42, description: 'Bed in double room' },
      { srcs:require('@/assets/img/gallery/service-centre/service-centre-005-s.webp'), src: require('@/assets/img/gallery/service-centre/service-centre-005.webp'), albumId: 4, id: 43, description: 'Single room' }
    ],
  }),
  mounted() {
    this.selectAlbum()

  },
  watch: {
    '$i18n.locale'(newVal) {
      if (newVal === 'afr') {
        this.filteredImages = this.selectedAlbum === 0 ? this.imagesAfrikaans : this.imagesAfrikaans.filter((x) => x.albumId == this.selectedAlbum)
      } else {
        this.filteredImages = this.selectedAlbum === 0 ? this.imagesEnglish : this.imagesEnglish.filter((x) => x.albumId == this.selectedAlbum)
      }
    }
  },
  methods: {
    selectAlbum() {
      if (this.$i18n.locale === 'afr') {
        this.filteredImages = this.selectedAlbum === 0 ? this.imagesAfrikaans : this.imagesAfrikaans.filter((x) => x.albumId == this.selectedAlbum)
      } else {
        this.filteredImages = this.selectedAlbum === 0 ? this.imagesEnglish : this.imagesEnglish.filter((x) => x.albumId == this.selectedAlbum)
      }
    },
    openDialog() {
      this.dialog = true
      this.$emit('openDialog');
    },
    closeDialog() {
      this.dialog = false
      this.$emit('closeDialog');
    }
  }
};

</script>

<style scoped>
.scroll {
  overflow-y: scroll
}

.portfolio {
  background: white;
}

.portfolio h2 {
  font-family: 'IBM Plex Serif SemiBold', sans-serif;
  color: #020381;
}

.gallery-card {
  background: whitesmoke;
  max-width: 85vw;
}

.gallery-container {
  background: white;
  max-height: 60vh;
  overflow-y: scroll;
  /* max-width: 85vw; */
  /* margin-block-start: auto; */
  /* margin-left: 25px; */
}

.gallery-container-modal {
  background: white;
  max-height: 90vh;
  overflow-y: scroll;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 25px auto;
  list-style: none;
  text-align: center;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 10px 18px 12px 18px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  color: #555555;
  transition: all 0.3s ease-in-out;
  margin: 0 4px 10px 4px;
  background: whitesmoke;
  border-radius: 4px;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  background: #0693E3;
  color: #fff;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(0, 0, 0, 0.6);
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(21, 21, 21, 0.6);
  position: absolute;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portfolio .portfolio-wrap .portfolio-info::before {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  top: 35px;
  left: 35px;
  border-top: 3px solid #fff;
  border-left: 3px solid #fff;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info::after {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 35px;
  right: 35px;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: #fff;
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: #020381;
}

.portfolio .portfolio-wrap:hover::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info::before {
  top: 15px;
  left: 15px;
}

.portfolio .portfolio-wrap:hover .portfolio-info::after {
  bottom: 15px;
  right: 15px;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #020381;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #020381;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(21, 21, 21, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;

}



.portfolio-details .portfolio-description p {
  padding: 0;
}
</style>