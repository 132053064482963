<template>
  <div>
    <!-- ======= Services Section ======= -->
    <section id="services" class="services">
      <v-row>
        <!-- Image Section -->
        <v-col class="align-self-center" :cols="12" :md="6" data-aos="fade-right">
          <v-card tile class="image" :img="require('@/assets/img/services-rentals.webp')">
          </v-card>
        </v-col>
        <!-- Text Section -->
        <v-col :cols="12" :md="6" data-aos="fade-left">
          <v-container class="content">
            <v-card flat tile class="services">
              <div class="section-title">
                <h2>{{ $t("services1.header") }}</h2>
              </div>
              <p>{{ $t("services1.paragraph1") }}</p>
              <ul>
                <li class="fst-italic"><i class="bx bx-chevron-right"></i> {{ $t("services1.listItem1") }}</li>
                <li class="fst-italic"><i class="bx bx-chevron-right"></i> {{ $t("services1.listItem2") }}</li>
                <li class="fst-italic"><i class="bx bx-chevron-right"></i> {{ $t("services1.listItem3") }}</li>
              </ul>
              
              <div class="subsection">
                <div class="section-subtitle mt-10">
                  <h5>{{ $t("services1.subheader1") }}</h5>
                </div>
                <ul>
                  <li><i class="bx bx-check"></i> {{ $t("services1.listItem4") }}</li>
                  <li><i class="bx bx-check"></i> {{ $t("services1.listItem5") }}</li>
                  <li><i class="bx bx-check"></i> {{ $t("services1.listItem6") }}</li>
                  <li><i class="bx bx-check"></i> {{ $t("services1.listItem7") }}</li>
                </ul>

                <div class="section-subtitle mt-10">
                  <h5>{{ $t("services1.subheader2") }}</h5>
                  <ul>
                    <li><i class="bx bx-check"></i> {{ $t("services1.listItem8") }}</li>
                    <li><i class="bx bx-check"></i> {{ $t("services1.listItem9") }}</li>
                    <li><i class="bx bx-check"></i> {{ $t("services1.listItem10") }}</li>
                  </ul>
                </div>

              </div>

              <p>{{ $t("services1.paragraph2") }}</p>
            </v-card>
          </v-container>
        </v-col>
      </v-row>

    </section><!-- End Services Section -->
  </div>
</template>

<script>
export default {
  data: () => ({
    //
  }),
};
</script>

<style scoped>
/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.subsection li {
  padding-bottom: 10px;
  display: flex;
  align-items: flex-start;
}
.section-subtitle h5 {
  padding-bottom: 10px;
  text-transform: uppercase;
  margin-bottom: 15px;
  /* font-family: 'IBM Plex Serif SemiBold', sans-serif; */
  color: #020381;
}

.section-subtitle p {
  margin-bottom: 0;
  color: #aeaeae;
}

#services {
  background: whitesmoke;
}

.services {
  background: whitesmoke;
}

.services h2 {
  font-family: 'IBM Plex Serif SemiBold', sans-serif;
  color: #020381;
}

.services .image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 65vh;
  display: flex; 
  justify-content: center; 
  align-items: center;
}


.services .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 5px 0 10px 60px;
}

.services .icon-box i {
  font-size: 48px;
  float: left;
  color: #020381;
}

.services .icon-box p {
  font-size: 15px;
  color: #959595;
  margin-left: 60px;
}


.services .content ul {
  list-style: none;
  padding: 0;
}

.services .content ul li {
  padding-bottom: 10px;
  display: flex;
  align-items: flex-start;
}

.services .content ul i {
  font-size: 24px;
  padding: 2px 6px 0 0;
  color: #020381;
}
</style>
