<template>
  <div>
    <!-- ======= About Us Section ======= -->
    <section id="about" class="about">
      <v-container class="content">
        <v-card flat tile data-aos="fade-up">
          <v-row>
            <v-col>
              <div class="section-title">
                <h2 class="mb-5">{{ $t("aboutUs.header") }}</h2>
              </div>
              <div class="centered-text">
                <p>{{ $t("aboutUs.subheader1") }}</p>
                <p>{{ $t("aboutUs.subheader2") }}</p>
                <p>{{ $t("aboutUs.subheader3") }}</p>
                <p>{{ $t("aboutUs.subheader4") }}</p>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <div>
          <!-- <v-btn @click = "startApplication = true">Start Application</v-btn> -->
        </div>
        <v-row>
          <v-col>
            <v-card data-aos="fade-up" tile class="image" :img="img">
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section><!-- End About Us Section -->
    <v-dialog v-model="startApplication" width="900px" >
      <Form @exit="exitFrom"/>
    </v-dialog>

  </div>
</template>

<script>
import Form from './Form.vue';
export default {
  components: {
    Form
  },
  data: () => ({
    users: [],
    loading: false,
    startApplication: false,
    img: require('@/assets/img/about.webp')
  }),
  computed: {

  },
  watch: {

  },
  beforeMount() {

  },
  created() {

  },
  beforeUpdate() {

  },
  mounted() {

  },
  methods: {
    exitFrom() {
      this.startApplication = false;
    }
  }
}
</script>

<style scoped>
/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.centered-text {
    text-align: center; /* Centers text horizontally */
  }
.section-title {
  text-align: center;
  padding-bottom: 10px;
}

.about {
  background-color: white;

}

.about h2 {
  font-family: 'IBM Plex Serif SemiBold', sans-serif;
  color: #020381;
}

.about img {
  max-width: 100%;
  height: auto;
}

.about .image {
  background: whitesmoke;
  width: 100%;
  height: auto;
  height: 100vh;
}

.about p {
  color: #000000;
  margin: 20px;

}

.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
  display: flex;
  align-items: flex-start;
}

.about .content ul i {
  font-size: 24px;
  padding: 2px 6px 0 0;
  color: #020381;
}

.about .content p:last-child {
  margin-bottom: 0;
}
</style>