<template>
  <div>
    <!-- ======= Hero Section ======= -->
    <section id="hero">

      <div class="hero-container">

        <a href="index.html" class="hero-logo" data-aos="zoom-in">
          <v-img eager max-height="40vh" contain :src="require('@/assets/img/logo_rounded.png')" alt="">
          </v-img eager></a>

        <h1 data-aos="zoom-in">{{ $t("hero.name") }}</h1>
        <h2 data-aos="fade-up">{{ $t("hero.slogan") }}</h2>
        <a data-aos="fade-up" href="#about" class="btn-get-started scrollto">{{ $t("hero.button") }}</a>
      </div>

    </section><!-- End Hero -->
    <div v-if="!upDog">
      <a  href="https://www.acvv.org.za/" target="_" class="d-flex align-items-center justify-content-center">
      <img :src="require('@/assets/img/acvv_logo_old_rounded.png')" class="acvv-logo" />
    </a>
    </div>
    
  </div>
</template>

<script>
export default {
  data: () => ({
    upDog: false,
  }),
  watch: {
    "$fullscreen"(val) {
      this.upDog = !val
    }
  }
};
</script>

<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/


#hero::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.50);
  z-index: 1;
}

#hero .hero-container {
  position: relative;
  z-index: 2;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 15px;
}

#hero .hero-logo {
  margin-bottom: 30px;
}

#hero h1 {
  margin: 0 0 30px 0;
  font-size: 48px;
  font-weight: 500;
  line-height: 56px;
  font-family: 'IBM Plex Serif SemiBold', sans-serif;
  color: #fff;
}

#hero h2 {
  color: #bdbaba;
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
}

#hero .btn-get-started {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 14px 30px;
  border-radius: 3px;
  margin: 10px;
  color: #fff;
  background: #0693E3;
}

#hero .btn-get-started:hover {
  transition: 0.5s;
  background: #020381;
  color: #fff;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    line-height: 22px;
  }
}
</style>