<template>
    <div>
        <!-- ======= Services Section ======= -->
        <section id="services2" class="services">
            <v-row>
                <!-- Text Section -->
                <v-col :cols="12" :md="6" data-aos="fade-right">
                    <v-container class="content">
                        <v-card flat tile class="services">
                            <div class="section-title">
                                <h2>{{ $t("services2.header") }}</h2>
                            </div>
                            <p>{{ $t("services2.paragraph1") }}</p>
                            <ul>
                                <li class="fst-italic"><i class="bx bx-chevron-right"></i> {{ $t("services2.listItem1") }}
                                </li>
                                <li class="fst-italic"><i class="bx bx-chevron-right"></i> {{ $t("services2.listItem2") }}
                                </li>
                                <li class="fst-italic"><i class="bx bx-chevron-right"></i> {{ $t("services2.listItem3") }}
                                </li>
                            </ul>
                        </v-card>
                    </v-container>
                </v-col>

                <!-- Image Section -->
                <v-col class="align-self-center" :cols="12" :md="6" data-aos="fade-left">
                    <v-card tile class="image"  :img="require('@/assets/img/frail-care.webp')">
                    </v-card>
                </v-col>
            </v-row>
        </section><!-- End Services Section -->
    </div>
</template>

<script>
export default {
    data: () => ({
        //
    }),
};
</script>

<style scoped>
/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/

#services2 {
    background: white;
}

.services h2 {
    font-family: 'IBM Plex Serif SemiBold', sans-serif;
    color: #020381;
}

.services .image {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 65vh;
}

.services .icon-box h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 5px 0 10px 60px;
}

.services .icon-box i {
    font-size: 48px;
    float: left;
    color: #020381;
}

.services .icon-box p {
    font-size: 15px;
    color: #959595;
    margin-left: 60px;
}


.services .content ul {
    list-style: none;
    padding: 0;
}

.services .content ul li {
    padding-bottom: 10px;
    display: flex;
    align-items: flex-start;
}

.services .content ul i {
    font-size: 24px;
    padding: 2px 6px 0 0;
    color: #020381;
}
</style>
