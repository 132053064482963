<template>
    <div>
      <v-card v-if="!isFormSubmitted">
        <v-card-title>
          Application Form
          <v-spacer></v-spacer>
          <v-btn icon @click="exitForm"><v-icon small> close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row v-for="(question, index) in currentQuestions" :key="index">
            <v-col cols="12">
              <template v-if="question.type === 'text'">
                <v-text-field v-model="question.answer" :label="question.question"></v-text-field>
              </template>
              <template v-else-if="question.type === 'radio'">
                <v-radio-group v-model="question.answer" :label="question.question" class="radio-group-inline">
                  <v-radio v-for="(option, optionIndex) in question.options" :key="optionIndex" :label="option"
                    :value="option"></v-radio>
                </v-radio-group>
              </template>
              <template v-else-if="question.type === 'checkbox'">
                <v-subheader>{{ question.question }}</v-subheader>
                <v-checkbox v-for="(option,optionIndex) in question.options" :key="optionIndex" :value="option" v-model="question.answer" :label="option"></v-checkbox>
              </template>
              <template v-else-if="question.type === 'selection'">
                <v-select v-model="question.answer" :label="question.question" :items="question.options"></v-select>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
        <v-row>
          <v-col cols="12">
            <v-btn @click="previousQuestions" v-if="currentPage > 1">Previous</v-btn>
            <v-btn @click="nextQuestions" v-if="currentPage < totalPages">Next</v-btn>
            <v-btn @click="submitForm">Submit</v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-container v-else>
        <h2>Thank you for submitting the form!</h2>
      </v-container>
    </div>
  </template>
  
  <script>
  import applicationQuestions from "../../static/applicationQuestions.json";
  export default {
  
    data() {
      return {
        questions: [],
        currentPage: 1,
        questionsPerPage: 5,
        isFormSubmitted: false
      };
    },
    created() {
      this.questions = applicationQuestions;
      console.log('this.questions', this.questions)
    },
    computed: {
      currentQuestions() {
        const startIndex = (this.currentPage - 1) * this.questionsPerPage;
        const endIndex = startIndex + this.questionsPerPage;
        return this.questions.slice(startIndex, endIndex);
      },
      totalPages() {
        return Math.ceil(this.questions.length / this.questionsPerPage);
      }
    },
    methods: {
      previousQuestions() {
        this.currentPage--;
      },
      nextQuestions() {
        this.currentPage++;
      },
      submitForm() {
        console.log('this.questions', this.questions)
        this.isFormSubmitted = true;
  
        //exit form
        this.exitForm();
        
      },
      exitForm() {
        this.questions= this.questions.map(question => {
          question.answer = "";
          return question;
        });
        this.isFormSubmitted = false;
        this.$emit("exit");
  
      }
    }
  };
  </script>
  
  <style scoped>
  .radio-group-inline .v-radio {
    display: inline-flex;
  }
  </style>
  