import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import api from './services/api'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import * as VueGoogleMaps from 'vue2-google-maps'
import 'viewerjs/dist/viewer.css'
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';
import employeeData from './static/employees.json'

import { AwsRum } from 'aws-rum-web';

try {
  const config = {
    sessionSampleRate: 1,
    identityPoolId: "eu-west-1:cf6529e7-9669-4fd0-9929-08d1cf0ebebe",
    endpoint: "https://dataplane.rum.eu-west-1.amazonaws.com",
    telemetries: ["performance","errors","http"],
    allowCookies: true,
    enableXRay: false
  };

  const APPLICATION_ID = '03f42d94-fde9-4730-98cf-ea34d4297ea4';
  const APPLICATION_VERSION = '1.0.0';
  const APPLICATION_REGION = 'eu-west-1';

  const awsRum = new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  );
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

const options = {
    // You can set your default options here
};

Vue.use(Toast, options)

Vue.prototype.$api = api
Vue.prototype.$galleryOpen = false
Vue.prototype.$employees = employeeData
Vue.prototype.$fullscreen = false

Vue.config.productionTip = false

Vue.component('vue-cookie-accept-decline', VueCookieAcceptDecline);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDKQhrgu9eo7S9FW0o8dLxacXxo_pcNVUU',
    libraries: 'places',
  }
});



new Vue({
  el: '#app',
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
